<template>
  <div>
    <loginDialog @loginSuccess="loginSuccess"></loginDialog>
  </div>
</template>

<script>
import loginDialog from "~hbs/components/common/loginDialog";
export default {
  metaInfo: {
    title: "登录",
  },
  components: { loginDialog },
  data() {
    return {};
  },
  computed: {},
  methods: {
    loginSuccess() {
      if (this.$route.query.redirect) {
        this.$router.push({
          path: this.$route.query.redirect,
        });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
